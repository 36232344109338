/* header css */

@font-face {
  font-family:'LexendDeca-Light';
  src: url(../fonts/LexendDeca-Light.ttf);
}

@font-face {
  font-family:'LexendDeca-Regular';
  src: url(../fonts/LexendDeca-Regular.ttf);
}

@font-face {
  font-family:'LexendDeca-Medium';
  src: url(../fonts/LexendDeca-Medium.ttf);
}

@font-face {
  font-family:'LexendDeca-SemiBold';
  src: url(../fonts/LexendDeca-SemiBold.ttf);
}

@font-face {
  font-family:'NoirPro';
  src: url(../fonts/NoirPro-Regular.woff);
}

@font-face {
  font-family:'NoirPro-Light';
  src: url(../fonts/NoirPro-Light.woff);
}

@font-face {
  font-family:'NoirPro-Medium';
  src: url(../fonts/NoirPro-Medium.woff);
}

@font-face {
  font-family:'NoirPro-SemiBold';
  src: url(../fonts/NoirPro-SemiBold.woff);
}

@font-face {
  font-family:'NoirPro-Bold';
  src: url(../fonts/NoirPro-Bold.woff);
}


/* demo */

/* option 1 table */

.clientDataMapTable .ant-table-thead > tr > th {
font-family: 'NoirPro-Medium';
background-color: #EFEFEF !important;
height: 50px;
color: #3b4452 !important;
font-size: 14px;
line-height: 14px;
}

.clientDataMapTable .ant-table-tbody > tr.ant-table-row:hover > td, 
.clientDataMapTable .ant-table-tbody > tr > td.ant-table-cell-row-hover {
background: #EFEFEF !important; 
}

.clientDataMapTable .ant-table-tbody > tr:nth-child(even) > td {
background-color: #F6F7F9;
}

/* .clientDataMapTable .ant-table-tbody > tr:nth-child(odd) > td {
background-color: #EFEFEF;
} */

.clientDataMapTable .ant-table-tbody > tr > td {
font-family: 'NoirPro';
padding:8px 16px;
}


/* option 2 table */

.ClientStatusTable .ant-table-thead > tr > th {
font-family: 'NoirPro';
background-color: #EFEFEF !important;
height: 50px;
color: #3b4452 !important;
font-size: 14px;
line-height: 14px;
}

.ClientStatusTable .ant-table-tbody > tr.ant-table-row:hover > td, 
.ClientStatusTable .ant-table-tbody > tr > td.ant-table-cell-row-hover {
background: #EFEFEF !important; 
}

.ClientStatusTable .ant-table-tbody > tr:nth-child(even) > td {
background-color: #F6F7F9;
}

/* .ClientStatusTable .ant-table-tbody > tr:nth-child(odd) > td {
background-color: #EFEFEF;
} */

.ClientStatusTable .ant-table-tbody > tr > td *:not(i) {
font-family:'NoirPro-Light';
}

.ClientStatusTable .anticon svg {
font-size: 12px;
}

.ClientStatusTable .ant-table-tbody > tr > td {
font-family:'NoirPro-Light';
padding:8px 16px;
}

.ClientStatusTable .ant-table-filter-trigger {
color:#3b4452;
}

.ClientStatusTable .ant-table-column-sorter {
  color:#3b4452; 
}

.ClientStatusTable .ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #3b4452;
}

.ClientStatusTable .ant-table-filter-trigger:hover {
  color: #3b4452;
}


/* table option 3 */

/* option 2 table */

.SolrCore .form__fields .input__block label {
font-family:'LexendDeca-Semibold' !important;
}

.SolrCore .form__fields .input__block input ,
.SolrCore .primary-btn {
font-family:'LexendDeca-Regular' !important;
}

.SolrCore .workarea__heading {
font-family: 'LexendDeca-Semibold';
}

.SolrCore .ant-table-thead > tr > th {
font-family: 'LexendDeca-Semibold';
background-color: #EFEFEF !important;
height: 50px;
color: #3b4452 !important;
font-size: 14px;
line-height: 14px;
}

.SolrCore .ant-table-tbody > tr.ant-table-row:hover > td, 
.SolrCore .ant-table-tbody > tr > td.ant-table-cell-row-hover {
background: #EFEFEF !important; 
}

.SolrCore .ant-table-tbody > tr:nth-child(even) > td {
background-color: #F6F7F9;
}

/* .ClientStatusTable .ant-table-tbody > tr:nth-child(odd) > td {
background-color: #EFEFEF;
} */

/* .SolrCore .ant-table-tbody > tr > td *:not(i) {
font-family:'LexendDeca-Medium';
} */

.SolrCore .anticon svg {
font-size: 12px;
}

.SolrCore .ant-table-tbody > tr > td {
font-family:'LexendDeca-Regular';
padding:8px 16px;
}

.SolrCore .ant-table-filter-trigger {
color:#3b4452;
}

.SolrCore .ant-table-column-sorter {
color:#3b4452; 
}

.SolrCore .ant-table-column-sorters:hover .ant-table-column-sorter {
color: #3b4452;
}

.SolrCore .ant-table-filter-trigger:hover {
color: #3b4452;
}

.SolrCore .workarea__heading {
font-size: 18px;
}

.SolrCore .ant-pagination-item a {
font-family:'LexendDeca-Regular' !important;
}


/* table option 3 ends */

/* fonts-option1 */

.CreatNewClientChanges *:not(.workarea__heading) {
font-family:'NoirPro-Light' !important;
}


/* fonts-option2 */

/* .ApplicationPermission *:not(.workarea__heading) {
font-family:'LexendDeca-Regular' !important;
}

.ApplicationPermission .workarea__heading {
  font-family:'LexendDeca-Medium' !important;
} */

/* demo ends */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'NoirPro';
  font-size: 14px;
}

body {
  background: transparent !important;
}

b {
  font-family:'NoirPro-Medium';
  font-weight: normal !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #1883b8;
  border-radius: 10px;
}

.display__none {
      /* display: none !important; */
    position: absolute !important;
    width: 0;
    opacity: 0;
}

.display__tablecell {
  /* display: table-cell !important; */
  position: relative !important;
  width: auto;
  opacity: 1;
}

.ck-voice-label {
display: none !important;
}

.manage__Link {
  color: white;
  text-decoration: none;
}

.manage__Link :hover {
  color: white;
  text-decoration: none;
}

.mandatory {
  font-size: 15px;
  color: #ff0000;
}

.product__img {
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  width: 60px;
}

.action__btns {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.action__btns .image__block {
  margin-right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action__btns .image__block:hover {
  background: #24abe2;
}

.action__btns i {
  color: #24abe2;
  font-size: 1rem;
}

.action__btns .image__block:hover i {
  color: #fff;
}

.action__btns .image__block:hover img {
  filter: invert(90%) sepia(93%) saturate(26%) hue-rotate(248deg)
    brightness(150%) contrast(150%);
}

.action__btns .image__block img {
  width: 15px;
}

.ant-picker {
border: 1px solid #bebfb6;
border-radius: 10px;
}

/* .ant-select-single.ant-select-show-arrow .ant-select-selection-item, 
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
color:#3b4452;
} */

.ant-picker:hover, .ant-picker-focused {
border: 1px solid #bebfb6;
box-shadow: none;
}

.ant-modal-body {
  max-height: 500px;
  overflow: auto;
}

.ant-modal-close-x {
  /* position: absolute;
  width: 25px;
  height: 25px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* top: -30px;
  right: 0px; */
}

.ant-modal-close-x .anticon-close svg {
  color: red;
  font-size: 14px;
  cursor: pointer;
}

.ant-table-filter-trigger {
  color: #fff;
}

.ant-table-column-sorter {
  color: #fff;
}

.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #fff;
}

.ant-table-filter-trigger:hover {
  color: #fff;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-thead > tr > th {
  background-color: #3b4452 !important;
  /* font-weight: 500; */
  font-family:'NoirPro-Medium';
  height: 50px;
  color: #fff !important;
  font-size: 14px;
  line-height: 14px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #efefef;
}

.ant-table-thead > tr > th img {
  filter: invert(100%) sepia(2%) saturate(154%) hue-rotate(201deg)
    brightness(120%) contrast(100%);
    vertical-align: middle;
    width: 14px;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.ant-table-container
  table
  > thead
  > tr:first-child
  th.ant-table-cell-scrollbar:last-child {
  border-top-right-radius: 8px;
  /* background-color: transparent !important; */
  box-shadow: none;
}

.ant-table-container .ant-table-header {
  border-radius: 10px 10px 0 0;
}

.ant-table-container table a {
  text-decoration: none;
  color: #24abe2;
  cursor: pointer;
}

.ant-table-container table a:hover {
  color: #1883b8;
}

.ant-modal-body .form__fields {
  min-height: auto;
}

.mh-auto {
  min-height: auto !important;
}

/* .ant-dropdown-placement-bottomRight {
  left: 10%;
  } */

.disabled {
  background-color: #dddddd !important;
  border: none !important;
}

input[type="range"]::-webkit-slider-runnable-track {
  /* background-color: #1883B8; */
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

input[type="range"][disabled]::-webkit-slider-runnable-track {
  border: 1px solid #d7dbdd;
  background-color: transparent;
  opacity: 0.4;
}

input[type="range"]::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

input[type="range"]::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

input[type="range"]::-ms-fill-lower {
  background-color: #d7dbdd;
  border-radius: 3px;
}

input[type="range"]::-ms-fill-upper {
  background-color: #d7dbdd;
  border-radius: 3px;
}

input[type="range"]::-ms-tooltip {
  display: none; /* display and visibility only */
}

input[type="range"]::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

input[type="range"]:active::-moz-range-thumb {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #1883b8;
  height: 15px;
  width: 15px;
  margin-top: -5px;
}

input[type="range"][disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #d7dbdd;
}

input[type="range"]:active::-webkit-slider-thumb {
  outline: none;
}

input[type="range"]::-ms-thumb {
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  border: none;
}

input[type="range"]:active::-ms-thumb {
  border: none;
}

/*FOR VALIDATION (BORDERS) */
.border__red {
  border: 2px solid red !important;
  border-radius: 10px;
  margin: 0;
}

.error-color-red {
  color: red !important;
}

.border__grey {
  border: 1px solid #bebfb6 !important;
  border-radius: 10px !important;
  padding: 1px;
}

.p-viewer,
.p-viewer2 {
  float: right;
  margin-top: -30px;
  margin-right: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

/* .fa-eye {
.p-viewer i {
  color: #000;
} */

.mr-1 {
  margin-right: 5px;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 15px;
}

.mr-2 {
  margin-right: 15px;
}

.pr-0 {
padding-right: 0 !important;
}

.pl-0 {
padding-left: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.text-right {
  text-align: right;
}

.chart__section {
  margin: 2rem 0;
}

.subheading {
  font-size: 16px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin: 1rem 0;
}

.markup__symbol {
position: absolute;
top: 35px;
right: 15px;
color:#bebfb6;
}


.circle-ripple {
  background-color: #ffffff47;
  border-radius: 50%;
  -webkit-animation: ripple 1.5s linear infinite;
  animation: ripple 1.5s linear infinite;
}

@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 5px #ffffff10;
  }
  100% {
    box-shadow: 0 0 0 10px #ffffff25;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 5px #ffffff10;
  }
  100% {
    box-shadow: 0 0 0 10px #ffffff25;
  }
}

.login__section {
  background: url(../images/demo-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 15px;
  /* height: 100vh; */
  /* min-height: 50rem; */
}

.login__section .login__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.login__section .login__header .logo__section img {
  width: 150px;
}

.login__section .right__text p {
  margin-bottom: 0;
  color: #fff;
}

/* .login__section .login__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  } */

.login__section .left__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40rem;
}

.login__section .left__section img {
  width: 100px;
  margin-bottom: 10px;
  cursor: pointer;
}

.login__section .left__section h4 {
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.login__section .form__section {
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login__section .form__section .login__block {
  width: 27rem;
  background: #0000008c;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #00000029;
  margin: 20px auto;
}

.login__section .form__section .login__block h3 {
  color: #24abe2;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.login__section .form__section .formlabel {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
}

.login__section .form__section .login-input {
  background: #fff;
  border-radius: 5px;
}

.login__section .form__section .ant-input-password-icon {
  color: #000;
}

.login__section .form__section .checkbox__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login__section .form__section .checkbox__block span {
  color: #fff;
}

.login__section .form__section .checkbox__block a {
  color: #fff;
  text-decoration: none;
  /* padding-bottom: 10px; */
  border-bottom: 1px solid;
}

.login__section .form__section .checkbox__block a:hover {
  color: #fff;
}

.login__section .form__section .newto__block h3 {
  color: #24abe2;
  font-size: 18px;
  /* font-weight: bold; */
  text-align: center;
}

.login__section .form__section .newto__block h3 span {
  color: #fff;
  font-size: 18px;
  /* font-weight: bold; */
  margin-left: 5px;
}

/* .login__section .login__slider {
  position: absolute;
  bottom: 8%;
} */

.login__section .slider__section {
padding: 20px 0;
border-top: 1px solid #bebfb6;
margin-top: 10px;
width: calc(100vw - 16px);
overflow: hidden;
}

.login__section .slider__section .slider__block {
  width: 80%;
}

/* .register__section {
  height: auto !important;
  background-size: cover;
} */

.register__section .form__fields {
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  min-height: auto;
}

.register__section .form__section .login__block {
  width: 45%;
}

.register__section .form__section .login__block .input__block label {
  color: #fff;
}

.register__section .form__section .login__block .input__block .contno__inptdiv {
  display: flex;
}

.register__section
  .form__section
  .login__block
  .input__block
  .contno__inptdiv
  .inpt__num {
  width: 20%;
}

/* .register__section .footer__section.login__footer {
  position: relative;
} */

.register__section .login__btn {
  color: #fff;
  margin-top: 1rem;
}

.register__section .login__btn a {
  color: #24abe2;
  text-decoration: none;
  margin-left: 5px;
}

/* .footer__section.login__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
} */

.ytvideo__modal {
  width: 60vw !important;
}

.ytvideo__modal .ant-modal-body {
  padding: 0;
}

.ytvideo__modal iframe {
  display: block;
}

.ytvideo__modal .ant-modal-close-x {
  position: absolute;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -30px;
  right: 0px;
}

.ytvideo__modal .ant-modal-close-x .anticon-close svg {
  color: red;
  font-size: 20px;
}

.ytvideo__modal .ant-modal-footer {
  display: none;
}

.main__section {
  display: flex;
  /* justify-content: flex-start;
  align-items: center; */
}

.main__section .header__section {
  height: 70px;
  background: #24abe2;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main__section .header__section .left__block {
  display: flex;
  align-items: center;
}

.main__section .header__section .top__nav ul {
  display: flex;
  margin: 0;
  list-style: none;
  padding-left: 0;
}

.main__section .header__section .top__nav ul li a {
  color: #fff;
  text-decoration: none;
  margin-right: 5px;
}

.main__section .header__section .top__nav ul li a label {
  cursor: pointer;
}

.main__section .header__section .top__nav ul li {
  position: relative;
}

.main__section .header__section .top__nav ul li:hover:after {
  content: "";
  background: #fff;
  position: absolute;
  width: 87%;
  height: 4px;
  left: -1px;
  bottom: -25px;
}

.main__section .header__section .top__nav ul li a:after {
  content: "|";
  color: #fff;
  margin-left: 5px;
}

.main__section .header__section .top__nav ul li:last-child a:after {
  display: none;
}

.main__section .header__section .menu__icon img {
  width: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.main__section .header__section .right__block {
  display: flex;
  align-items: center;
}

.main__section .header__section .user__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 9rem;
}

.main__section .header__section .user__block h3 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
}

.main__section .header__section .user__block h4 {
  font-size: 12px;
  color: #fff;
  margin-bottom: 0;
}

.main__section .header__section .user__block .user__details {
  text-align: right;
}

.main__section .header__section .search__block {
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}

.main__section .header__section .search__block:hover .search__input {
  width: 150px;
  opacity: 1;
  transition: all 0.5s;
}

.main__section .header__section .search__block .search__input {
  border: 1px solid #fff;
  border-radius: 5px 0 0 5px;
  background: #fff;
  opacity: 0;
  width: 0;
  transition: all 0.5s;
}

.main__section .header__section .search__block .search__button {
  background: transparent;
  border: none;
  color: #fff;
  width: 20px;
  height: 23px;
  border-radius: 0px 5px 5px 0px;
  transition: all 0.5s;
}

.main__section .header__section .search__block:hover button {
  width: 20px;
  color: #3b4452;
  border-radius: 0px 5px 5px 0px;
  background: #fff;
  transition: all 0.5s;
}

/* sidenav */

/* .ant-menu.ant-menu-dark, 
  .ant-menu-dark .ant-menu-sub, 
  .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #1f263e ;
  } */

  /* .ant-menu-sub.ant-menu-inline > .ant-menu-item, 
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 50px !important;
  } */

.main__section .sidenav__section {
  width: 17%;
  background: #1f263e;
  height: auto;
  /* padding:20px; */
  padding-top: 0;
  transition: all 0.5s;
}

.main__section .sidenav__section .main__menu {
  height: calc(100vh - 5rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.main__section .sidenav__section.partialwidth {
  width: 6%;
  transition: all 0.5s;
}

/* .main__section .sidenav__section .ant-menu {
  padding-left: 10px;
  padding-right: 10px;
  } */

.main__section .sidenav__section .logo__section {
  /* width: 220px; */
  height: 70px;
  display: flex;
  align-items: center;
}

.main__section .sidenav__section .logo__section .logobig {
  width: 160px;
  margin: auto;
}

.main__section .sidenav__section .logo__section .logosmall {
  width: 40px;
  margin: auto;
}

.main__section .sidenav__section .accordion .accordion-item {
  background: transparent;
  border: none;
}

.main__section .sidenav__section img.ant-menu-item-icon {
  width: 18px;
}

.main__section .sidenav__section .accordion .accordion {
  margin-left: 25px;
}

.main__section .sidenav__section .accordion .accordion-item .accordion-button {
  background: transparent !important;
  color: #bebfb6 !important;
  padding: 0;
  padding-bottom: 15px;
}

.main__section .sidenav__section .accordion .accordion-button:not(.collapsed) {
  box-shadow: none !important;
  color: #24abe2 !important;
  /* padding:0; */
}

.main__section .sidenav__section .accordion .accordion-button::after {
  /* content:"\f078";
  font-family: FontAwesome; */
  background-image: url(../images/icons/chevron-down.svg);
  background-size: 75%;
  background-position: center center;
}

.main__section
  .sidenav__section
  .accordion
  .accordion-button:not(.collapsed)::after {
  color: #24abe2 !important;
  margin-right: 2px;
}

.main__section .sidenav__section .accordion .accordion-body {
  padding: 0;
}

.main__section .sidenav__section ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
  background: #1f263e;
}

.main__section .sidenav__section ul li a {
  color: #bebfb6;
  text-decoration: none;
  /* margin-bottom: 15px; */
  display: block;
  white-space: pre-wrap;
}

.main__section .sidenav__section .help__menu .ant-menu-item {
  background: transparent !important;
  padding-left: 20px !important;
}

.main__section .sidenav__section .help__menu .ant-menu-item:hover {
  background: transparent !important;
}

.main__section .sidenav__section .menu__button {
  background: none;
  color: #24abe2;
  padding: 5px;
  border: 1px solid #24abe2;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ant-menu-vertical .menu__button {
  background: none;
  color: #24abe2;
  padding: 5px;
  border: none;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ant-menu-vertical .menu__button img {
  margin-right: 5px;
}

.main__section .sidenav__section .menu__button img {
  margin-right: 5px;
}

.ant-menu-vertical a {
  text-decoration: none;
}

.ant-menu-vertical .ant-menu-item:hover {
  color: #24abe2;
  background-color: #303750;
}

.ant-menu-vertical .ant-menu-item-selected {
  color: #24abe2 !important;
  background-color: #303750 !important;
}

.ant-menu-vertical .ant-menu-item-selected .menu__label {
  color: #24abe2;
}

.ant-menu-vertical .menu__label {
  color: #bebfb6;
  display: block;
}

.ant-menu-vertical a:hover .menu__label {
  color: #24abe2;
}

.main__section .sidenav__section ul li a:hover {
  color: #24abe2;
}

.main__section .sidenav__section ul li:hover {
  color: #24abe2;
  background-color: #303750;
}

.main__section .sidenav__section ul li:hover > div > img {
  filter: invert(58%) sepia(43%) saturate(1185%) hue-rotate(164deg)
  brightness(120%) contrast(90%);
}

.main__section .sidenav__section ul li:hover > div {
  color: #24abe2;
}

.main__section .sidenav__section .ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected {
  color: #24abe2;
  background-color: #303750;
}

.main__section
  .ant-menu-dark
  .ant-menu-item:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.main__section
  .ant-menu-dark
  .ant-menu-item-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after,
.main__section
  .ant-menu-dark
  .ant-menu-item:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.main__section
  .ant-menu-dark
  .ant-menu-item-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-active
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.main__section
  .ant-menu-dark
  .ant-menu-submenu-title:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before {
  background-color: #24abe2;
}

/* .main__section .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow, 
  .main__section .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow, 
  .main__section .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 1;
  } */

.main__section
  .sidenav__section
  .ant-menu-dark
  .ant-menu-submenu-open
  > div
  > img {
  filter: invert(58%) sepia(43%) saturate(1185%) hue-rotate(164deg)
    brightness(93%) contrast(90%);
}

.main__section
  .sidenav__section
  .ant-menu-dark
  .ant-menu-submenu-selected
  > div
  > img {
  filter: invert(58%) sepia(43%) saturate(1185%) hue-rotate(164deg)
    brightness(93%) contrast(90%);
}

.main__section
  .sidenav__section
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  > span
  > a {
  color: #24abe2;
}

.main__section
  .sidenav__section
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  color: #24abe2;
  background-color: #303750 !important;
}

.main__section .sidenav__section .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #1f263e;
}

/* .main__section .sidenav__section .ant-menu-dark .ant-menu-sub, 
  .main__section .sidenav__section .ant-menu.ant-menu-dark .ant-menu-sub {
  width: 220px;
  } */

.main__section .sidenav__section .accordion .menu__head {
  display: flex;
  align-items: center;
}

.main__section .sidenav__section .accordion .menu__head img {
  width: 17px;
  margin-right: 10px;
}

.main__section .sidenav__section .accordion .menu__head h3 {
  font-size: 14px;
  color: #bebfb6;
  margin-bottom: 0;
  font-weight: 600;
}

.main__section
  .sidenav__section
  .accordion-button:not(.collapsed)
  .menu__head
  h3 {
  color: #24abe2 !important;
}

.main__section .sidenav__section.partialwidth .logo__section {
  width: 80px;
}

.main__section .sidenav__section .logo__section .logosmall {
  display: none;
}

.main__section .sidenav__section.partialwidth .logo__section .logobig {
  display: none;
}

.main__section .sidenav__section.partialwidth .logo__section .logosmall {
  display: block;
}

/* .main__section .sidenav__section.partialwidth {
  } */

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: normal !important;
  list-style: none !important;
}

.main__section .sidenav__section.partialwidth .menu__head h3 {
  display: none;
}

.main__section .right__section {
  width: 83%;
  transition: all 0.5s;
}

.main__section .right__section.partialwidth {
  width: 94%;
}

.main__section .content__area {
  background: #f6f7f9;
  min-height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  padding: 20px;
  overflow-y: scroll;
}

.footer__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #242424;
  padding: 0 1rem;
}

.footer__section .left__block {
  display: flex;
  align-items: center;
}

.footer__section .left__block .footer__logo img {
  width: 125px;
}

.footer__section .left__block .footer__menu ul {
  display: flex;
  list-style: none;
  margin-bottom: 0;
}

.footer__section .left__block .footer__menu ul li a {
  color: #fff;
  margin-right: 10px;
  text-decoration: none;
}

.footer__section .right__block ul {
  display: flex;
  list-style: none;
  margin-bottom: 0;
}

.footer__section .right__block ul li a img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

/*Global CSS */

.form__fields {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  min-height: calc(100vh - 170px);
}

.form__fields .input__block {
  position: relative;
  margin-bottom: 20px;
}

.ant-btn-primary {
  background: #1883b8;
  border-color: #1883b8;
}

.ant-btn-primary:hover {
  background: #24abe2;
  border-color: #24abe2;
}

.ck.ck-editor__main {
  height: 300px;
  width: 100.7%;
  overflow-y: scroll;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 300px;
}

.primary-btn {
  background: #1883b8;
  border-radius: 5px !important;
  color: #fff;
  border: none;
  padding: 0px 10px;
  height: 35px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.ant-btn {
  border-radius: 5px;
  padding: 0px 10px;
}

.disabled-btn {
  background: #a2a3a4;
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 0px 10px;
  height: 35px;
  cursor: not-allowed;
}

.ant-btn.disabled-btn {
  background: #a2a3a4;
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 0px 10px;
  height: 35px;
  cursor: not-allowed;
}

.ant-btn.disabled-btn:hover {
background: #a2a3a4 !important;
border-radius: 5px;
color: #fff;
border: none;
padding: 0px 10px;
height: 35px;
cursor: not-allowed;
}

.primary-btn a {
  color: #fff !important;
  text-decoration: none;
}

.primary-btn i {
  margin-right: 5px;
  vertical-align: initial;
  font-size: 18px;
}

.primary-btn img {
  margin-right: 5px;
  vertical-align: sub;
}

.primary-btn:hover {
  color: #fff;
  background: #24abe2;
}

.ant-switch-checked {
  background-color: #1883b8 !important;
}

.ant-switch {
  background-color: #000;
}

.form__fields .input__block .image_block {
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.form__fields .input__block .image_block .divimage_block {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  text-align: center;
}

.form__fields .input__block .image_block .divimage_block span {
  display: block;
  color: #b3b3b3;
}

.form__fields .input__block .image_block .divimage_block span .requires_txt {
  display: inline;
  color: red;
}

.form__fields .input__block .image_block .divimage_block input[type="file"] {
  display: none;
}

.form__fields .input__block .image_block .divimage_block .choose_file_upload {
  background: #1883b8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 8px 10px;
  display: inline-block;
  height: 40px;
  cursor: pointer;
}

.form__fields
  .input__block
  .image_block
  .divimage_block
  .choose_file_upload:hover {
  background: #24abe2;
}

.form__fields .input__block .image_block .choose_file_divimg {
  position: relative;
  padding: 15px;
}

.form__fields .input__block .image_block .choose_file_divimg img {
  width: 50%;
  /* height: 200px; */
  display: block;
  margin: 0 auto;
}

.form__fields .input__block .image_block .preview__img {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.form__fields .input__block .image_block .choose_file_divimg span {
  position: absolute;
  right: 120px;
  top: 0;
  color: #ff0000;
  font-size: 16px;
  cursor: pointer;
}

.form__fields .accordion .accordion-item {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 2px;
  margin-top: 8px;
}

.form__fields .accordion .accordion-item {
  background: #efefef;
  border: none;
}

.form__fields .accordion .accordion-item:not(:first-of-type) {
  background: #efefef;
  border: none;
}

.form__fields .accordion .accordion-item .accordion-header .accordion-button {
  padding: 12px;
  background: transparent;
}

.form__fields .accordion .accordion-item .accordion-body {
  background: #fff;
  border-radius: 0 0 10px 10px;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  color: #212529;
  background-color: transparent;
  box-shadow: none;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button::after {
  background-image: none;
  content: "+";
  font-size: 22px;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "-";
  transform: inherit;
  font-size: 22px;
}

.form__fields .accordion .accordion-item .accordion-header label {
  margin-left: 8px;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  input {
  vertical-align: middle;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  label {
  margin-left: 0px;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  input[type="checkbox"] {
  margin-right: 8px;
}

.form__fields
  .accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordian_chkbxdiv {
  padding-left: 10px;
}

.form__fields .input__block label {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #3b4452;
  opacity: 1;
  margin-bottom: 5px;
}

.form__fields .input__block label span {
  font-size: 15px;
  color: red;
}

.form__fields .input__block label img {
  margin-left: 5px;
  width: 14px;
}

.form__fields .input__block .check_txt {
  position: absolute;
  right: 10px;
  top: 0px;
  color: #24abe2;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.form__fields .input__block input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 10px 5px 10px;
  width: 100%;
  height: 35px;
  color:#3b4452;
}

.form__fields .input__block textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  width: 100%;
  height: 150px;
}


.form__fields .input__block input::placeholder {
  text-align: left;
  letter-spacing: 0px;
  color: #bebfb6;
  opacity: 1;
  font-size: 14px;
}

.form__fields .input__block textarea::placeholder {
  text-align: left;
  letter-spacing: 0px;
  color: #bebfb6;
  opacity: 1;
  font-size: 14px;
}

.form__fields .input__block .ant-select {
width: 100%;
padding: 0;
}

.ant-select-selection-placeholder {
color:#bebfb6;
}

.ant-select-selection-item {
color: #3B4452;
}


.form__fields
  .input__block
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: none;
  border-radius: 10px !important;
  color: #bebfb6 !important;
}

/* .divForm .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.divForm .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}

.divForm .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
} */

/* .modal__field .input__block label {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #3b4452;
  opacity: 1;
  margin-bottom: 5px;
  }
  
  .modal__field .input__block .ant-select {
  width: 100%;
  padding: 5px 5px 5px 5px;
  }
  
  .modal__field .input__block .ant-switch-checked {
  background-color: #1883b8 !important;
  }
  
  .modal__field .input__block .ant-switch {
  background-color: #000;
  } */

.catalog__list .catalog__topnav {
  padding: 10px;
  background: #fff;
  box-shadow: 6px 6px 10px 0px #e7e7e7;
  margin-bottom: 10px;
}

.catalog__list .catalog__topnav ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
}

.catalog__list .catalog__topnav ul li a {
  color: #3b4452;
  text-decoration: none;
  margin-right: 10px;
}

.catalog__list .catalog__topnav ul li a span {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: red;
  border-radius: 50%;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
}

.catalog__list .catalog__topnav ul li {
  position: relative;
}

.catalog__list .catalog__topnav ul li a:after {
  content: "|";
  color: #ccc;
  margin-left: 10px;
}

.catalog__list .catalog__topnav ul li:last-child a:after {
  display: none;
}

/* .catalog__list .slider__section  {
  width:calc(100vw - 280px);
  } */

.catalog__list .slider__section .slider__block {
  padding: 1rem;
  min-height: 12rem;
  border-radius: 10px;
  overflow: hidden;
}

.catalog__list .slider__section .slider__block .bgimage {
  position: relative;
  width: 100%;
  height: 100%;
}

.catalog__list .slider__section .slider__block .bgimage img {
  transform: scale(1.3);
  transition: all 0.3s;
}

.catalog__list .slider__section .slider__block:hover .bgimage img {
  transform: scale(1.5);
  transition: all 0.3s;
}

.catalog__list .slider__section .slider__block .slider__content {
  position: absolute;
  top: 10%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
}

.catalog__list .slider__section .slider__block .logo__img {
  display: flex;
  justify-content: flex-end;
}

.catalog__list .slider__section .slider__block .logo__img img {
  width: 100px;
}

.catalog__list .slider__section .slider__block .slider__btns {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* .catalog__list .slider__section .slider__block .slider__btns button {
  margin-right: 10px;
  } */

.catalog__list .slider__section .owl-dots {
  display: none;
}

.catalog__list .slider__section .owl-nav {
  position: absolute;
  top: 37%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.catalog__list .slider__section .owl-nav button span {
  font-size: 20px;
  margin-top: -3px;
}

.catalog__list .slider__section .owl-nav .owl-prev {
  background: #fff;
  width: 2rem;
  height: 2rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -14px;
  border: 1px solid #ccc;
}

.catalog__list .slider__section .owl-nav .owl-next {
  background: #fff;
  width: 2rem;
  height: 2rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -14px;
  border: 1px solid #ccc;
}

.catalog__list .slider__section .owl-nav .owl-prev:hover,
.catalog__list .slider__section .owl-nav .owl-next:hover {
  background: #1883b8;
}

.catalog__list .whole__block {
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
}

.catalog__list .whole__block .top__image {
  position: relative;
  overflow: hidden;
}

.catalog__list .whole__block .top__image img {
  transform: scale(1);
  transition: all 0.3s;
  width: 100%;
}

.catalog__list .whole__block .top__image:hover img {
  transform: scale(1.3);
  transition: all 0.3s;
}

.catalog__list .whole__block .top__image h3 {
  position: absolute;
  bottom: 0;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  width: 100%;
  padding: 10px 20px;
}

.catalog__list .whole__block .bottom__details {
  padding: 15px;
  min-height: 25rem;
}

.catalog__list .whole__block .bottom__details .content__block {
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 1rem;
  min-height: 90px;
}

.catalog__list .whole__block .bottom__details .content__block:hover {
  background: #24abe2;
}

.catalog__list .whole__block .bottom__details .content__block:hover .count,
.catalog__list .whole__block .bottom__details .content__block:hover .text {
  color: #fff;
}

.catalog__list .whole__block .bottom__details .content__block .count {
  font-size: 18px;
  color: #24abe2;
  font-weight: 600;
}

.catalog__list .whole__block .bottom__details .content__block .text {
  font-size: 14px;
  color: #252f3e;
}

.dashbo_maindiv .slider__section .slider__item {
  position: relative;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.dashbo_maindiv .slider__section .slider__item .bgimage {
  position: relative;
  width: 100%;
  height: 100%;
}

.dashbo_maindiv .slider__section .slider__item .bgimage img {
  transform: scale(1.3);
  transition: all 0.3s;
  width: 100%;
  height: 200px;
}

.dashbo_maindiv .slider__section .slider__item:hover .bgimage img {
  transform: scale(1.5);
  transition: all 0.3s;
}

.dashbo_maindiv .slider__section .slider__item .slider__content {
  position: absolute;
  top: 10%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 96%;
}

.dashbo_maindiv .slider__section .slider__item .logo__img {
  display: flex;
  justify-content: flex-end;
}

.dashbo_maindiv .slider__section .slider__item .logo__img img {
  width: 100px;
}

.dashbo_maindiv .slider__section .slider__item .slider__btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dashbo_maindiv .count_div {
  background-color: #24abe2;
  border-radius: 8px;
  padding: 15px;
}

.dashbo_maindiv .count_div div:nth-child(1) span {
  color: #fff;
  font-size: 15px;
}

.dashbo_maindiv .count_div div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.dashbo_maindiv .count_div div:nth-child(2) span {
  color: #fff;
  font-size: 13px;
}

.dashbo_maindiv .count_div div:nth-child(2) img {
  width: 22px;
  height: 22px;
}

.dashbo_maindiv .vendors_section {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 15px 15px 15px;
}

.dashbo_maindiv .vendors_section .slider__block {
  position: relative;
  padding: 1rem;
  min-height: 12rem;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.dashbo_maindiv .vendors_section .slider__block .bgimage {
  position: relative;
  width: 100%;
  height: 100%;
}

.dashbo_maindiv .vendors_section .slider__block .bgimage img {
  transform: scale(1.3);
  transition: all 0.3s;
  width: 100%;
  height: 200px;
}

.dashbo_maindiv .vendors_section .slider__block:hover {
  box-shadow: 0px 0px 8px 3px #bebfb6;
}

.dashbo_maindiv .vendors_section .slider__block:hover .bgimage img {
  transform: scale(1.5);
  transition: all 0.3s;
}

.dashbo_maindiv .vendors_section .slider__block:hover .slider__btns {
  display: flex;
}

.dashbo_maindiv .vendors_section .slider__block .slider__content {
  position: absolute;
  top: 10%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
}

.dashbo_maindiv .vendors_section .slider__block .logo__img {
  display: flex;
  justify-content: flex-end;
}

.dashbo_maindiv .vendors_section .slider__block .logo__img img {
  width: 100px;
}

.dashbo_maindiv .vendors_section .slider__block .slider__btns {
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.dashbo_maindiv .Notif_section {
  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
}

.dashbo_maindiv .Notif_section .msg_div {
  border-bottom: 1px solid #bebfb6;
  padding-bottom: 15px;
  margin-top: 15px;
}

.dashbo_maindiv .Notif_section .msg_div:last-child {
  border-bottom: none;
  background: #000;
}

.dashbo_maindiv .Notif_section .msg_div .msg_date {
  font-size: 15px;
  color: #252f3e;
  font-weight: 600;
}

.dashbo_maindiv .Notif_section .msg_div .msg_divtext {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.dashbo_maindiv .Notif_section .msg_div .msg_divtext .msg_txt {
  font-size: 15px;
  color: #3b4452;
}

.dashbo_maindiv .Notif_section .msg_div .msg_divtext .msg_nametxt {
  font-size: 15px;
  color: #24abe2;
  margin-left: 2px;
  cursor: pointer;
}

.dashbo_maindiv .Notif_section .msg_div .msg_divtext .msg_timetxt {
  color: #bebfb6;
  font-size: 15px;
}

.allprod_section .filter_section {
  background-color: #fff;
  padding: 25px;
  margin-top: 20px;
}

.allprod_section .btn_div {
  display: flex;
  justify-content: flex-end;
}

.allprod_section .btn_div .primary-btn {
  margin-right: 15px;
}

.allprod_section .btn_div .primary-btn:last-child {
  margin-right: 0;
}

.allprod_section .btn_div .primary-btn img {
  vertical-align: bottom;
}

.drpdwn__moreaction .ant-dropdown-menu-title-content {
  text-align: center;
}

.allprod_section .filter_section .filt_optndiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.allprod_section .filter_section .filt_optndiv .gview__div {
  display: flex;
  align-items: center;
}

.allprod_section .filter_section .filt_optndiv .gview__div .grid_icon {
  width: 35px;
  height: 35px;
  font-size: 21px;
  color: #bebfb6;
  background: #f6f7f9;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #bebfb6;
  cursor: pointer;
  vertical-align: middle;
}

.allprod_section .filter_section .filt_optndiv .gview__div .grid_icon:hover {
  color: #24abe2;
  border: 1px solid #24abe2;
}

.allprod_section .filter_section .filt_optndiv .gview__div .grid_icon.active {
  color: #24abe2;
  border: 1px solid #24abe2;
}

.allprod_section .filter_section .filt_optndiv .gview__div .list_icon {
  width: 35px;
  height: 35px;
  font-size: 21px;
  color: #bebfb6;
  background: #f6f7f9;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #bebfb6;
  margin-left: 15px;
  cursor: pointer;
  vertical-align: middle;
}

.allprod_section .filter_section .filt_optndiv .gview__div .list_icon:hover {
  color: #24abe2;
  border: 1px solid #24abe2;
}

.allprod_section .filter_section .filt_optndiv .gview__div .list_icon.active {
  color: #24abe2;
  border: 1px solid #24abe2;
}

.allprod_section .filter_section .filt_optndiv .gview__div .pageno__txt {
  display: inline-block;
  width: 80px;
  height: 38px;
  background: #f6f7f9 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  text-align: center;
  padding: 6px;
}

.allprod_section .filter_section .filt_optndiv .gview__div .left_icon {
  width: 35px;
  height: 35px;
  font-size: 21px;
  text-align: center;
  color: #bebfb6;
  background: #f6f7f9;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #bebfb6;
  margin-left: 4px;
  margin-right: 6px;
  cursor: pointer;
  vertical-align: middle;
}

.allprod_section .filter_section .filt_optndiv .gview__div .right_icon {
  width: 35px;
  height: 35px;
  font-size: 21px;
  text-align: center;
  color: #bebfb6;
  background: #f6f7f9;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid #bebfb6;
  margin-left: 6px;
  cursor: pointer;
  vertical-align: middle;
}

.allprod_section
  .filter_section
  .filt_optndiv
  .drpdwn__div
  .select_inpt
  .ant-select-selector {
  width: 60px;
  font-size: 15px;
  color: #bebfb6;
  background: #f6f7f9;
  border-radius: 6px;
  border: 1px solid #bebfb6;
}

.allprod_section .filter_section .filter_inpsection {
  background-color: #f6f7f9;
  margin-top: 15px;
  transition: 0.5s;
  min-height: auto;
}

.allprod_section .filter_section .filter_inpsection.hide {
  display: none;
}

.allprod_section .filter_section .filter_inpsection .filtbtn_div {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.allprod_section .filter_section .filtbtn__div {
  display: flex;
  align-items: center;
}

.allprod_section .filter_section .filtbtn__div .select__all {
  display: flex;
  align-items: center;
}

.select__all label {
  margin-left: 5px;
}

/* new range slider */

.ant-slider-handle {
position: absolute;
width: 14px;
height: 14px;
margin-top: -5px;
background-color: #1883b8;
border: solid 2px #1883b8;
border-radius: 50%;
cursor: pointer;
box-shadow: none !important;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color:#1883b8;
}

.ant-slider-track, .ant-slider:hover .ant-slider-track {
background-color: #1883b8;
}

.ant-slider-handle:focus {
box-shadow: none;
}

.ant-slider-handle.ant-tooltip-open {
  border-color:#1883b8 !important;
}

.rangeSlider__values {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rangeSlider__values .input__block {
margin-bottom: 0;
}

.rangeSlider__values .input__block input {
  width: 80px;
  height: 25px;
  color: #bebfb6;
  background-color: #fff;
  font-size: 12px;
  margin-top: 0;
  margin-bottom:0;
  border: 1px solid #bebfb6;
  padding: 2px 6px;
  border-radius: 10px;
}

.rangeSlider__values .input__block .dollar__symbol {
  position: absolute;
  left: 6px;
  top: 3px;
  color: #ccc;
}

/*Slider multiRange -- jash   */
.sliderrange__maindiv {
  position: relative;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 3px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #1883b8;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  width: 60px;
  height: 25px;
  color: #bebfb6;
  background-color: #fff;
  font-size: 12px;
  margin-top: 20px;
  border: 1px solid #bebfb6;
  padding: 2px 6px;
  border-radius: 10px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* / Removing the default appearance / */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: -webkit-fill-available;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* / For Chrome browsers / */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;

  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* / For Firefox browsers / */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;

  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.allprod_section .filter_section .prod__section .prod__maindiv {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background: #fff;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}

.allprod_section .filter_section .prod__section .prod__maindiv .prodimg__div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .prodimg__div
  img {
  transform: scale(1);
  transition: all 0.3s;
  width: 100%;
  height: 200px;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .prodimg__div:hover
  img {
  transform: scale(1.4);
  transition: all 0.3s;
}

.allprod_section .filter_section .prod__section .prod__maindiv .details__div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .details__div
  .style__txt {
  display: block;
  font-size: 14px;
  color: #3b4452;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .details__div
  .price__txt {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #3b4452;
}

.allprod_section .filter_section .prod__section .prod__maindiv .editdel__div {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .editdel__div
  div {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 4px;
  margin-right: 15px;
  cursor: pointer;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .editdel__div
  div:hover {
  background: #24abe2;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .editdel__div
  div:hover
  img {
  filter: invert(90%) sepia(93%) saturate(26%) hue-rotate(248deg)
    brightness(150%) contrast(150%);
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .editdel__div
  div
  img {
  width: 16px;
  height: 16px;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .editdel__div
  div
  a {
  font-size: 0;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .editdel__div
  div:last-child {
  margin: 0;
}

.allprod_section .filter_section .prod__section .prod__maindiv .info__div {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
}

/* .allprod_section .filter_section .prod__section .prod__maindiv .info__div:hover + .descr__div  {
    display: block;
  } */

.allprod_section .filter_section .prod__section .prod__maindiv .info__div img {
  width: 70%;
}

.allprod_section .filter_section .prod__section .prod__maindiv .descr__div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  color: #fff;
  background-color: rgb(0 0 0 / 80%);
  padding: 25px 15px;
  padding-top: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.allprod_section .filter_section .prod__section .prod__maindiv .descr__div p {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2px;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .descr__div
  p
  span {
  flex: 50%;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .descr__div
  .close__div {
  position: absolute;
  top: 12px;
  right: 20px;
}

.allprod_section
  .filter_section
  .prod__section
  .prod__maindiv
  .descr__div
  .close__div
  img {
  width: 11px;
  height: 11px;
  cursor: pointer;
}

.allprod_section .filter_section .lviewprod__tbl .ant-table-header th {
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  padding: 14px 8px;
  background: #fff;
  border-top: 1px solid #c8c9ca;
  border-bottom: none;
}

.allprod_section .filter_section .lviewprod__tbl .ant-table-header th:before {
  display: none;
}

.allprod_section
  .filter_section
  .lviewprod__tbl
  .ant-table-body
  > table
  tbody
  > tr {
  border: 1px solid #c8c9ca;
}

.allprod_section .filter_section .lviewprod__tbl .ant-table-body tbody td {
  padding: 10px 8px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}

/*.allprod_section .filter_section .lviewprod__tbl .ant-table-body tbody td:first-child {
    border-left: 1px solid grey;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .allprod_section .filter_section .lviewprod__tbl .ant-table-body tbody td:last-child {
    border-right: 1px solid grey;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }*/

.allprod_section .filter_section .lviewprod__tbl .ant-table-body td span {
  display: block;
  font-size: 13px;
  line-height: 16px;
}

.allprod_section .filter_section .lviewprod__tbl .ant-table-body .prod__img {
  width: 100%;
  border: 1px solid #c8c9ca;
  border-radius: 10px;
}

.allprod_section .filter_section .lviewprod__tbl .ant-table-body .gemfind__txt {
  font-size: 14px;
}

.allprod_section
  .filter_section
  .lviewprod__tbl
  .ant-table-body
  .ellipicon__img {
  display: block;
  width: 4px;
  margin: 0 auto;
  cursor: pointer;
}

.drpdwn__icons {
  padding: 0;
}

.drpdwn__icons .ant-dropdown-menu-item:hover {
  background: #24abe2;
}

.drpdwn__icons
  .ant-dropdown-menu-item:hover
  .ant-dropdown-menu-title-content
  img {
  filter: invert(90%) sepia(93%) saturate(26%) hue-rotate(248deg)
    brightness(150%) contrast(150%);
}

.drpdwn__icons .ant-dropdown-menu-item .ant-dropdown-menu-title-content img {
  width: 16px;
  height: 16px;
}

.allprod_section .filter_section .lviewprod__tbl .ant-pagination {
  justify-content: flex-start;
}

/* .allprod_section
  .filter_section
  .lviewprod__tbl
  .ant-pagination-disabled
  .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  width: 35px;
  height: 35px;
  font-size: 10px;
  text-align: center;
  color: #bebfb6;
  background: #f6f7f9;
  border-radius: 6px;
  border: 1px solid #bebfb6;
} */

.allprod_section
  .filter_section
  .lviewprod__tbl
  .ant-pagination
  .ant-pagination-item-1 {
  width: 80px;
  height: 35px;
  background: #f6f7f9;
  border-radius: 6px;
  border: 1px solid #bebfb6;
}

.prodetail__section .prod__maindiv {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 25px;
}

.prodetail__section .prod__headdiv .subheading img {
  width: 16px;
  margin-right: 6px;
}

.prodetail__section .prod__headdiv .prod__switchdiv {
  text-align: right;
}

.prodetail__section .prod__headdiv .prod__switchdiv .price__txt {
  margin-right: 10px;
}

.prodetail__section .prod__headdiv .prod__switchdiv .ant-switch {
  background-color: #000;
}

.prodetail__section .prod__headdiv .prod__switchdiv .ant-switch-checked {
  background-color: #1883b8 !important;
}

.prodetail__section .prod__maindiv .prodimg__div {
  border: 1px solid #c8c9ca;
  border-radius: 10px;
  padding: 1rem;
  text-align: center;
}

.prodetail__section .prod__maindiv .prodimg__div img {
  width: 100%;
}
.prodetail__section .prod__maindiv .prodesc__div .tab__div .nav-item {
  margin-right: 10px;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__div
  .nav-item:last-child {
  margin-right: 0;
}

.prodetail__section .prod__maindiv .prodesc__div .tab__div .nav-item .nav-link {
  background: #fff;
  color: #000;
  border: 1px solid #bebfb6;
  border-radius: 10px 10px 0px 0px;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__div
  .nav-item
  .nav-link.active {
  background: #1883b8;
  color: #fff;
  border: 1px solid #1883b8;
  border-radius: 10px 10px 0px 0px;
}

.prodetail__section .prod__maindiv .prodesc__div .tab__contentdiv .head__div {
  background: #3b4452;
  border: 1px solid #3b4452;
  border-radius: 0px 10px 0px 0px;
  padding: 12px;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__contentdiv
  .head__div
  h4 {
  font-size: 16px;
  color: #fff;
  margin: 0;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__contentdiv
  .desc__maindiv
  .desc__div {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border: 1px solid #f6f7f9;
  padding: 12px;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__contentdiv
  .desc__maindiv
  .desc__div:nth-child(odd) {
  background: #ffffff;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__contentdiv
  .desc__maindiv
  .desc__div:nth-child(even) {
  background: #f6f7f9;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__contentdiv
  .desc__maindiv
  .desc__div
  .head__txt {
  flex: 20%;
}

.prodetail__section
  .prod__maindiv
  .prodesc__div
  .tab__contentdiv
  .desc__maindiv
  .desc__div
  .desc__txt {
  flex: 80%;
}

.diamond_section .dealer_btndiv button {
  width: 80px;
}

.newretailer_section .form__fields .retailer__btndiv {
  margin-top: 24px;
}

.newretailer_section .form__fields .retailer__btndiv button {
  width: 80px;
}

.vendorate_section .vendor_tbldiv .ant-table-tbody input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 2px 5px 2px 5px;
  width: 80%;
}

.vendorate_section .vendor_tbldiv .ant-table-tbody button {
  width: 80px;
}

.limit_section .chckbox__div input {
  margin-top: 4px;
  margin-right: 6px;
}

.limit_section .aplyfilt__btndiv {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.limit_section .aplyfilt__btndiv button i {
  margin-right: 4px;
}

.limit_section .options__div {
  background-color: #e5e9ed;
  padding: 15px;
}

.limit_section .options__div .rules__div .rules__txt {
  font-size: 20px;
}

.limit_section .options__div .rules__div .rules__txt .rules__txtno {
  font-size: 20px;
  color: #1883b8;
}

.limit_section .options__div .crud__div {
  display: flex;
  justify-content: flex-end;
}

.limit_section .options__div .crud__div .select__txt {
  font-size: 18px;
  margin-right: 8px;
  align-self: center;
}

.limit_section .options__div .crud__div .primary-btn {
  margin-right: 8px;
}

.limit_section .options__div .crud__div .primary-btn:last-child {
  margin-right: 0;
}

.limit_section .limit_tbldiv .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.modal__limit .ant-modal-content .ant-modal-footer {
  border-top: none !important;
}

.modal__limit .ant-modal-content .ant-modal-body .ant-radio-wrapper {
  display: block;
}

.modal__limit .ant-modal-content .ant-modal-body .primary-btn {
  display: block;
  margin-top: 4%;
}

.legacy_section .form__fields .subheading a {
  font-size: 14px;
  text-decoration: none;
  color: #24abe2;
}

.admintools__section .form__fields .servup__txt {
  margin-top: 15px;
}

.admintools__section .form__fields .servup__txt .jewspeed__txt {
  text-decoration: none;
  color: #1883b8;
}


.admintools__section .form__fields .rapnet__txt .date__txt {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.admintools__section .form__fields .name__txt span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-left: 2px;
}

.admintools__section .form__fields .phone__txt span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-left: 2px;
}


.admintools__section .form__fields .type__txt span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-left: 2px;
}

.admintools__section .form__fields .verify__txt span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-left: 2px;
}


.admintools__section .form__fields .approve__txt span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-left: 2px;
}



.admintools__section .form__fields .uname__txt span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-left: 2px;
}


.admintools__section .form__fields .psd__txt span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-left: 2px;
}

.admintools__section .form__fields .psd__txt .edit__txt {
  cursor: pointer;
}

.admintools__section .form__fields .viewp__txt {
  color: #24abe2;
  cursor: pointer;
}

.admintools__section .form__fields .dwnload__txt {
  text-decoration: none;
  cursor: pointer;
  color: #24abe2;
}

.admintools__section .form__fields .primary-btn {
  margin-top: 10px;
}

.admintools__section .form__fields .returnjewel__txt {
  text-decoration: none;
  cursor: pointer;
  margin-left: 4px;
  color: #24abe2;
}

.modal__section
  .ant-modal-content
  .ant-modal-body
  .modal__body
  .modal__bannerdiv
  img {
  width: 100%;
  height: 200px;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .modal__imgdiv {
  text-align: center;
}

.modal__section
  .ant-modal-content
  .ant-modal-body
  .modal__body
  .modal__imgdiv
  img {
  border: 1px solid #e3e3e3;
  padding: 10px;
}

.modal__section
  .ant-modal-content
  .ant-modal-body
  .modal__body
  .favdiamond__txt {
  font-size: 18px;
  color: #000;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .activ__txt {
  font-size: 15px;
  color: #000;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .prod__txt {
  font-size: 15px;
  color: #1883b8;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .cont__txt {
  font-size: 15px;
  color: #000;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .mailid__txt {
  font-size: 15px;
  text-decoration: none;
  color: #1883b8;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .contnum__txt {
  font-size: 15px;
  color: #000;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .vendtag__txt {
  font-size: 15px;
  color: #000;
}

.modal__section .ant-modal-content .ant-modal-body .modal__body .notag__txt {
  font-size: 15px;
  color: #1883b8;
}

.appermission__section .form__fields .viewsroom__txt {
  display: inline-block;
  text-decoration: none;
  margin-top: 6px;
}

.appermission__section .form__fields .chkbox__maindiv {
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
}

.appermission__section .form__fields .chkbox__maindiv .chkbox__headdiv label {
  margin-left: 4px;
  cursor: pointer;
}

.appermission__section .form__fields .chkbox__maindiv .chkbox__div {
  margin-top: 8px;
}

.appermission__section .form__fields .chkbox__maindiv .chkbox__div label {
  margin-left: 4px;
  cursor: pointer;
}

.appermission__section .form__fields .chkbox__maindiv .chckbox__subdiv {
  margin-top: 8px;
  padding: 0 15px;
}

.appermission__section .form__fields .chkbox__maindiv .diamsrch__txt {
  font-size: 14px;
  /* font-weight: 600; */
  /* font-family:'NoirPro-Medium'; */
}

.appermission__section .form__fields .chkbox__maindiv .chckbox__subdiv div {
  margin-top: 8px;
}

.appermission__section
  .form__fields
  .chkbox__maindiv
  .chckbox__subdiv
  div
  label {
  font-size: 14px;
  color: #000;
  margin-left: 4px;
  cursor: pointer;
}

.prod__maindiv.data__maping {
  padding: 0;
  border: none;
  background: transparent;
}

.prodetail__section
  .prod__maindiv.data__maping
  .prodesc__div
  .tab__contentdiv
  .desc__maindiv
  .desc__div:nth-child(even) {
  background: #fff;
}

.prodetail__section .prod__maindiv .scroll__div {
  max-height: 30rem;
  overflow: scroll;
}

.data__maping .subhead {
  margin: 1rem 0;
}

/* .data__maping .tab-content {
  max-height: 35rem;
  overflow-x: hidden;
  overflow-y: auto;
} */

.data__maping .input__field {
  background: #ffffff;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  width: 50%;
}

.emailTemplate .emailDetailBlock h1 {
  font-size: 16px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.emailTemplate .emailDetailBlock h2 {
  font-size: 14px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.emailTemplate .emailDetailBlock {
  background: #f6f7f9;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
}

.emailTemplate .emailDetailBlock ul {
  list-style: none;
  padding-left: 16px;
}

.emailTemplate .emailDetailBlock ul li a {
  color: #000;
  margin-bottom: 5px;
  text-decoration: none;
}

.magento__mapping .heading__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.designer__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  background: #f6f7f9;
  border-radius: 10px;
}

.designer__list .designer__block {
  display: flex;
  width: 25%;
  margin-bottom: 10px;
  align-items: center;
}

.designer__list .designer__block input {
  margin-right: 5px;
}

.workarea__heading {
  font-size: 20px;
  margin: 2rem 0;
}

.metal__section .form__fields .metal__maindiv {
  width: 60%;
  flex: 60%;
}

.metal__section .form__fields .metal__maindiv .head__div {
  display: flex;
  flex-wrap: wrap;
  background-color: #e5e9ed;
}

.metal__section .form__fields .metal__maindiv .head__div div:nth-child(1) {
  flex: 1;
  border-right: 1px solid #f0f0f0;
  padding: 15px;
}

.metal__section .form__fields .metal__maindiv .head__div div:nth-child(2) {
  flex: 1;
  padding: 15px;
}

.metal__section .form__fields .metal__maindiv .head__div span {
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.metal__section .form__fields .metal__maindiv .content__div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
}

.metal__section .form__fields .metal__maindiv .content__div div:nth-child(1) {
  flex: 1;
  padding: 15px;
}

.metal__section .form__fields .metal__maindiv .content__div div:nth-child(2) {
  flex: 1;
  padding: 15px;
}

.metal__section .form__fields .metal__maindiv .content__div span {
  font-size: 14px;
  color: #000;
}

.metal__section .form__fields .metal__maindiv .content__div input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  width: 70%;
}

.permission_section .form__fields .add__btndiv {
  text-align: right;
}

/* .permission_section
  .form__fields
  .permission_tbldiv
  .ant-table-thead
  > tr
  > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.permission_section
  .form__fields
  .permission_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.permission_section
  .form__fields
  .permission_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
} */

.permission_section
  .form__fields
  .permission_tbldiv
  .ant-table-body
  .input__block {
  margin: 0;
}

.rpermission__section .form__fields .chckbox__maindiv div {
  margin-top: 6px;
}

.rpermission__section .form__fields .chckbox__maindiv div label {
  margin-left: 6px;
  cursor: pointer;
}

.adminprefr__section .form__fields .radioinp__maindiv div {
  margin-top: 6px;
}

.adminprefr__section .form__fields .radioinp__maindiv div label {
  margin-left: 6px;
  cursor: pointer;
}

/* .blockdesign_section
  .form__fields
  .blockdesign_tbldiv
  .ant-table-thead
  > tr
  > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.blockdesign_section
  .form__fields
  .blockdesign_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.blockdesign_section
  .form__fields
  .blockdesign_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
} */

.consupld__section .form__fields .addcons__btndiv button:first-child {
  margin-right: 20px;
}

.consupld__section .form__fields .srchbox__div {
  text-align: right;
}

/* .consupld__section .form__fields .consupld_tbldiv .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.consupld__section
  .form__fields
  .consupld_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.consupld__section
  .form__fields
  .consupld_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
} */

.consupld__section .form__fields .consupld_tbldiv .ant-table-body .edit__icon {
  font-size: 18px;
  cursor: pointer;
}

.consupld__section .form__fields .consupld_tbldiv .ant-table-body .del__icon {
  font-size: 18px;
  cursor: pointer;
}

.modalconsupld__section
  .ant-modal-content
  .ant-modal-body
  .form__fields
  .input__block
  .radio__div
  input {
  width: auto;
}

.modalconsupld__section
  .ant-modal-content
  .ant-modal-body
  .form__fields
  .input__block
  .radio__div
  label {
  display: inline-block;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 10px;
}

.modalconsupld__section
  .ant-modal-content
  .ant-modal-body
  .form__fields
  .add__btndiv {
  text-align: right;
}

.modalconsupld__section
  .ant-modal-content
  .ant-modal-body
  .form__fields
  .add__btndiv
  .cancl__btn {
  color: #000;
  text-decoration: none;
  margin-bottom: 5px;
  display: inline-block;
}

.transfzz__section .form__fields .replall__radidiv {
  display: flex;
}

.transfzz__section .form__fields .replall__radidiv div label {
  cursor: pointer;
}

.transfzz__section .form__fields .replall__radidiv div:first-child {
  margin-right: 10px;
}

.loginpreference__section .image_block .divimage_block {
  position: relative;
}

.loginpreference__section .image_block .divimage_block .img__div {
  width: 200px;
  height: 150px;
  display: flex;
  align-items: center;
  background-color: #1883b8;
  border-radius: 10px;
  margin: 0 auto;
  padding: 15px;
}

.loginpreference__section .image_block .divimage_block .close_image {
  position: absolute;
  right: 10px;
  top: 12px;
}

.tradeshow__section .form__fields .addedit__btndiv {
  text-align: right;
}

.tradeshow__section .form__fields .addedit__btndiv button {
  margin-right: 10px;
}

.tradeshow__section .form__fields .addedit__btndiv button:last-child {
  margin-right: 0;
}

/* .tradeshow__section .form__fields .tradeshow_tbldiv .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.tradeshow__section
  .form__fields
  .tradeshow_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.tradeshow__section
  .form__fields
  .tradeshow_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
} */

.tradeshow__section
  .form__fields
  .tradeshow_tbldiv
  .ant-table-container
  .ant-table-body
  .icons__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tradeshow__section
  .form__fields
  .tradeshow_tbldiv
  .ant-table-container
  .ant-table-body
  .icons__div
  img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.modal__tradeshow .ant-modal-body .form__fields .divimage_block {
  position: relative;
  width: 50%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.modal__tradeshow
  .ant-modal-body
  .form__fields
  .divimage_block
  input[type="file"] {
  display: none;
}

.modal__tradeshow .ant-modal-body .form__fields .divimage_block span {
  display: block;
  color: #b3b3b3;
  margin-top: 4px;
}

.modal__tradeshow
  .ant-modal-body
  .form__fields
  .divimage_block
  .img__showdiv
  img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.modal__tradeshow
  .ant-modal-body
  .form__fields
  .divimage_block
  .img__showdiv
  span {
  position: absolute;
  right: 6px;
  top: 0;
  color: #ff0000;
  font-size: 16px;
  cursor: pointer;
}

.modal__tradeshow
  .ant-modal-body
  .form__fields
  .divimage_block
  .choose_file_upload {
  background: #1883b8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 8px 10px;
  display: inline-block;
  height: 40px;
  cursor: pointer;
}

.modal__tradeshow
  .ant-modal-body
  .form__fields
  .divimage_block
  .choose_file_upload:hover {
  background: #24abe2;
}

.modal__tradeshow .navigation__cat .checkbox__block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;
}

.modal__tradeshow .navigation__cat .checkbox__block input {
  width: auto;
  margin-right: 5px;
}

.addcolor__section .form__fields .addcolor__btndiv {
  text-align: right;
}

/* .addcolor__section .form__fields .addcolor_tbldiv .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.addcolor__section
  .form__fields
  .addcolor_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.addcolor__section
  .form__fields
  .addcolor_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
} */

.mailinglist__section .form__fields .dwnld__div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e5e9ed;
  padding: 15px;
  border-radius: 6px;
}

.mailinglist__section .form__fields .dwnld__div .mailist__txt {
  font-size: 18px;
}

.mailinglist__section .form__fields .dwnld__div .mailist__txt .no__txt {
  font-size: 18px;
  color: #1883b8;
}

.uplaod__file .right__sec {
  background: #f6f7f9;
  padding: 16px;
  border-radius: 10px;
  height: 100%;
}

.uplaod__file .right__sec h5 {
  font-size: 16px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  margin-bottom: 1rem;
}

.uplaod__file .right__sec ul {
  padding-left: 16px;
  list-style: circle;
  color: #24abe2;
}

.uplaod__file .right__sec ul li p {
  color: #000;
}

.uplaod__file .right__sec .download__block img {
  width: 30px;
}

.uplaod__file .right__sec .download__block a {
  color: #24abe2;
  text-decoration: none;
}

.uplaod__file .load__img label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.uplaod__file .load__img label input {
  width: auto;
  margin-right: 5px;
}

.uplaod__file .load__img label span {
  color: #3b4452;
  font-size: 14px;
}

.uplaod__file .radio__block input {
  width: auto;
}

.uplaod__file .radio__block {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.uplaod__file .radio__block .radio__btn {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.uplaod__file .radio__block .radio__btn label {
  margin-bottom: 0;
}

.radio__block {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.radio__block input {
  width: auto !important;
  margin-right: 5px;
}

.radio__block label {
  margin-bottom: 0 !important;
}

/* .mailinglist__section .form__fields .mailist_tbldiv .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.mailinglist__section
  .form__fields
  .mailist_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.mailinglist__section
  .form__fields
  .mailist_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
} */

/* .campaign__section .form__fields .campaign_tbldiv .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 14px;
}

.campaign__section
  .form__fields
  .campaign_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}

.campaign__section
  .form__fields
  .campaign_tbldiv
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
} */

.prod__maindiv.prodmarkup__secion {
  padding: 0;
  border: none;
  background: transparent;
}

.prodmarkup__secion .subhead {
  margin: 1rem 0;
}

.prodmarkup__secion .tab-content {
  /* max-height: 35rem;
  overflow-x: hidden;
  overflow-y: auto; */
}

.prodmarkup__secion .tab-content .tab__contentdiv .head__div {
  border-radius: 0 !important;
}

.prodmarkup__secion .tab-content .mrkup__inptdiv {
  position: relative;
}

.prodmarkup__secion
  .tab-content
  .mrkup__inptdiv
  .dmrkup__inpt::-webkit-inner-spin-button,
.prodmarkup__secion
  .tab-content
  .mrkup__inptdiv
  .dmrkup__inpt::-webkit-outer-spin-button {
  opacity: 1;
}

.prodmarkup__secion .tab-content .mrkup__inptdiv .mrkup__rangeinpt {
  padding-left: 20px;
}

.prodmarkup__secion .tab-content .mrkup__inptdiv .dollr__txt {
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 14px;
  color: #757575;
}

.prodmarkup__secion .tab-content .mrkup__inptdiv .ampcent__txt {
  content: "%";
  width: 20px;
  position: absolute;
  top: 4px;
  right: 20px;
  font-size: 16px;
}

.prodmarkup__secion .input__field {
  background: #ffffff;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  width: 50%;
}

.addcollection__section .form__fields .input__block .divimage_block {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  text-align: center;
}
.addcollection__section .form__fields .input__block .divimage_block img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.addcollection__section
  .form__fields
  .input__block
  .divimage_block
  .img__showdiv
  span {
  position: absolute;
  right: 6px;
  top: 0;
  color: #ff0000;
  font-size: 16px;
  cursor: pointer;
}
.addcollection__section .form__fields .input__block .divimage_block span {
  display: block;
  color: #b3b3b3;
  margin-top: 4px;
}
.addcollection__section
  .form__fields
  .input__block
  .divimage_block
  .requires_txt {
  display: inline;
  color: red;
}

.addcollection__section
  .form__fields
  .input__block
  .divimage_block
  input[type="file"] {
  display: none;
}

.addcollection__section
  .form__fields
  .input__block
  .divimage_block
  .choose_file_upload {
  background: #1883b8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 8px 10px;
  display: inline-block;
  height: 40px;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  width: 50%;
}

.addcollection__section
  .form__fields
  .input__block
  .divimage_block
  .choose_file_upload:hover {
  background: #24abe2;
}

.addcollection__section .form__fields .input__block .choose_file_divimg {
  position: relative;
  padding: 15px;
}

.addcollection__section .form__fields .input__block .choose_file_divimg img {
  width: 50%;
  /* height: 200px; */
  display: block;
  margin: 0 auto;
}

.addcollection__section .form__fields .input__block .preview__img {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.addcollection__section .form__fields .input__block .choose_file_divimg span {
  position: absolute;
  right: 20px;
  top: 0;
  color: #ff0000;
  font-size: 16px;
  cursor: pointer;
}

.addcollection__section .form__fields .chkbox__div {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.addcollection__section .form__fields .chkbox__div div {
  display: flex;
  align-items: center;
}

.addcollection__section .form__fields .chkbox__div div input {
  width: auto;
}

.addcollection__section .form__fields .chkbox__div div label {
  margin: 0;
  margin-left: 4px;
  cursor: pointer;
}

.vdiamond_section .filter_section {
  position: relative;
}

.vdiamond_section .filter_section .filter_inpsection {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 12;
  background-color: #f6f7f9;
  margin-top: 15px;
  transition: 0.5s;
  max-height: 400px;
  min-height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.vdiamond_section .filter_section .filter_inpsection.hide {
  display: none;
}

.vdiamond_section .filter_section .filter_inpsection .filtbtn_div {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.vdiamond_section .filter_section .filter_inpsection .lbl__txt {
  display: block;
  text-align: left;
  letter-spacing: 0px;
  color: #3b4452;
  opacity: 1;
  margin-bottom: 5px;
}

.vdiamond_section .filter_section .filter_inpsection .sliderrange__maindiv {
  margin-bottom: 80px;
}

.vdiamond_section .filter_section .filter_inpsection .imgselct__div {
  display: flex;
  flex-wrap: wrap;
}

.vdiamond_section .filter_section .filter_inpsection .imgselct__div div {
  width: 60px;
  padding: 2px;
  border: 1px solid #c6c6c6;
  cursor: pointer;
  text-align: center;
  margin-top: 8px;
  margin-right: 6px;
}

.vdiamond_section
  .filter_section
  .filter_inpsection
  .imgselct__div
  div.selected {
  border-bottom: 4px solid #24abe2;
}

.vdiamond_section
  .filter_section
  .filter_inpsection
  .imgselct__div
  div:last-child {
  margin-right: 0;
}

.vdiamond_section .filter_section .filter_inpsection .imgselct__div div img {
  width: 30px;
  height: 30px;
}

.vdiamond_section .filter_section .filter_inpsection .imgselct__div div span {
  font-size: 11px;
  display: block;
  margin-top: 4px;
}

.vdiamond_section .filter_section .filter_inpsection .imgselct__div .all__div {
  border: 1px solid #c6c6c6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vdiamond_section
  .filter_section
  .filter_inpsection
  .imgselct__div
  .all__div
  span {
  font-size: 12px;
  color: #000;
}

.vdiamond_section .filter_section .filter_inpsection .radio__div div input {
  width: auto;
}

.vdiamond_section .filter_section .filter_inpsection .radio__div div label {
  display: inline;
  cursor: pointer;
  margin-left: 5px;
}

.vdiamond_section
  .filter_section
  .filter_inpsection
  input[type="number"]::-webkit-inner-spin-button,
.vdiamond_section
  .filter_section
  .filter_inpsection
  input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.vdiamond_section .filter_section .filter_inpsection .ampcent__txt {
  content: "%";
  width: 20px;
  color: #bebfb6;
  background-color:#fff;
  position: absolute;
  top: 4px;
  right: 25px;
  font-size: 16px;
}

.vdiamond_section .options__div {
  background-color: #e5e9ed;
  padding: 15px;
}

.vdiamond_section .options__div .primary-btn i {
  font-size: 15px;
  vertical-align: text-bottom;
}

.vdiamond_section .options__div .add__div {
  display: flex;
  align-items: center;
}

.vdiamond_section .options__div .add__div .primary-btn {
  margin-left: 12px;
}

.vdiamond_section .options__div .add__div .diamnd__txt {
  font-size: 18px;
}

.vdiamond_section .options__div .add__div .diamnd__txt .txt__no {
  font-size: 18px;
  color: #1883b8;
}

.vdiamond_section .options__div .print__div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.vdiamond_section .options__div .print__div .select__txt {
  font-size: 16px;
  margin-right: 12px;
  align-self: center;
}

.vdiamond_section .options__div .print__div .select__txt .no__txt {
  font-size: 16px;
  color: #1883b8;
}

.vdiamond_section .options__div .print__div .primary-btn {
  margin-right: 12px;
}

.vdiamond_section .options__div .print__div .primary-btn:last-child {
  margin-right: 0;
}

.vdiamond_section .vdiamond__table .ant-table table {
  width: 1500px;
}

.vdiamond_section .vdiamond__table .ant-table table .gia__txt {
  color: #24abe2;
  cursor: pointer;
}

.vdiamond_section .vdiamond__table .ant-table table .dealer__txt {
  color: #24abe2;
  cursor: pointer;
}

.vdiamond_section .vdiamond__table .ant-table table .dealer__txt img {
  filter: invert(75%) sepia(42%) saturate(6667%) hue-rotate(163deg) brightness(97%) contrast(82%);
}

.vdiamond_section .vdiamond__table .ant-table table .infoicon__div i {
  font-size: 16px;
  color: #24abe2;
  cursor: pointer;
}

.vdiamond_section .vdiamond__table .ant-table table .vidicn__div i {
  font-size: 16px;
  color: #24abe2;
  cursor: pointer;
}

/* .vdiamond_section
  .vdiamond__table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-radius: 0;
} */

.modalvdiamond__section .form__fields .info__div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modalvdiamond__section .form__fields .info__div div {
  flex: 30%;
  margin-right: 2%;
}

.modalvdiamond__section .form__fields .info__div div p {
  display: flex;
  justify-content: space-between;
}

.modalvdiamond__section .form__fields .info__div div p .head__txt {
  font-weight: 500;
  font-family:'NoirPro-Medium';
  flex: 40%;
}

.modalvdiamond__section .form__fields .info__div div p .val__txt {
  flex: 60%;
}

.modalvdiamond__section .form__fields .info__div div p a {
  font-size: 14px !important;
  color: #1883b8 !important;
  text-decoration: none;
}

.modalvdiamond__section .form__fields .VideoIframe {
  width: 100%;
  margin: 0 auto;
  height: 420px;
  border: none;
}

.jwlsrch__section .filter_section .filt_optndiv .rslsrch__txt {
  font-size: 15px;
  color: #000;
  margin-left: 15px;
}

.jwlsrch__section .filter_section .filt_optndiv .rslsrch__txt .no__txt {
  color: #24abe2;
}

.jwlsrch__section .filter_section .filt_optndiv .selct__txt {
  font-size: 15px;
  color: #000;
}

.jwlsrch__section .filter_section .filt_optndiv .selct__txt .no__txt {
  color: #24abe2;
}

.shipping_section .shipping_tbldiv .ant-table-body .inpt__div {
  position: relative;
}

.shipping_section .shipping_tbldiv .ant-table-body .inpt__div input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  width: 80%;
  padding-left: 15px;
}

.shipping_section .shipping_tbldiv .ant-table-body .inpt__div span {
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 14px;
  color: #545454;
}

.navigation__section .form__fields .form__section .desc__div {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border: 1px solid #f6f7f9;
  padding: 12px;
}

.navigation__section .form__fields .form__section .desc__div:nth-child(odd) {
  background: #ffffff;
}

.navigation__section .form__fields .form__section .desc__div:nth-child(even) {
  background: #f6f7f9;
}

.navigation__section .form__fields .form__section .desc__div .headtxt__div {
  flex: 50%;
  display: flex;
  align-items: center;
}

.navigation__section
  .form__fields
  .form__section
  .desc__div
  .headtxt__div
  label {
  margin-left: 4px;
  cursor: pointer;
}

.navigation__section .form__fields .form__section .desc__div .inpt__div {
  flex: 50%;
}

.navigation__section
  .form__fields
  .form__section
  .desc__div
  .inpt__div
  .input__block {
  margin: 0;
}

.navigation__section .form__fields .form__section .desc__div .selct__div {
  display: flex;
}

.navigation__section
  .form__fields
  .form__section
  .desc__div
  .selct__div
  .input__block:not(:first-child) {
  margin-left: 6px;
}

.dlegacy__section .form__fields .input__block input {
  width: 50% !important;
}

.dlegacy__section .form__fields .input__block .selct__inpt {
  width: 50% !important;
}

.shopintgrate__section .form__fields .link__txt {
  text-decoration: none;
}

.shopintgrate__section .form__fields .headred__txt {
  color: red;
}

.shopintgrate__section .form__fields p {
  margin: 0;
}

.markups__section .content__div .head__div {
  background: #3b4452;
  border: 1px solid #3b4452;
  padding: 12px;
}

.markups__section .content__div .head__div h4 {
  font-size: 16px;
  color: #fff;
  margin: 0;
}

.markups__section .content__div .mrkup__inptdiv {
  position: relative;
}

.markups__section .content__div .mrkup__inptdiv .ampcent__txt {
  content: "%";
  width: 20px;
  position: absolute;
  top: 4px;
  right: 20px;
  font-size: 16px;
}

.markups__section .content__div .mrkup__inptdiv .dollr__txt {
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 14px;
  color: #757575;
}

.markups__section .content__div .mrkup__inptdiv .mrkup__rangeinpt {
  padding-left: 20px;
}

.markups__section
  .content__div
  .mrkup__inptdiv
  .dmrkup__inpt::-webkit-inner-spin-button,
.markups__section
  .content__div
  .mrkup__inptdiv
  .dmrkup__inpt::-webkit-outer-spin-button {
  opacity: 1;
}

.settings__section .filter_section .settings__tbl .ant-table-body .prod__img {
  width: 100%;
  border: 1px solid #c8c9ca;
  border-radius: 10px;
}

.topnav__toggle {
  display: none;
}

.mobile__icon,
.mobileMenuclose {
  display: none;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #fff;
  background: #24abe2 !important;
}
.main__section .header__section .top__nav li.active:after {
  content: "";
  background: #fff;
  position: absolute;
  width: 87%;
  height: 4px;
  left: -1px;
  bottom: -25px;
}

.diamond__mapping .diamondmapping__links {
  background: #f6f7f9;
  padding: 1rem;
  border-radius: 10px;
}

.diamond__mapping .diamondmapping__links a {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  color: #24abe2;
}

.app__preview .diamond__details .text__block .diamond__imgs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.app__preview .diamond__details .text__block .diamond__imgs .img__block {
  width: 65px;
  height: 65px;
  text-align: center;
  border: 1px solid #ccc;
}

.app__preview .diamond__details .text__block .diamond__imgs .img__block:hover {
  border-bottom: 3px solid #24abe2;
}

.app__preview
  .diamond__details
  .text__block
  .diamond__imgs
  .img__block.selected {
  border-bottom: 3px solid #24abe2;
}

.app__preview .diamond__details .text__block .diamond__imgs .img__block img {
  width: 40px;
  height: 40px;
}

.app__preview .diamond__details .table__block {
  margin-top: 5rem;
}

.app__preview .selection__block {
  margin: 0.5rem 0;
}

.app__preview .selection__block .selection__box {
  border: 1px solid #ccc;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.app__preview .selection__block .selection__box.selected {
  background: #24abe2;
  color: #fff;
}

.app__preview .selection__block .selection__box:hover {
  background: #24abe2;
  color: #fff;
}

.app__preview .selection__block .selection__box p {
  margin-bottom: 0;
}

.app__preview .image__block {
  padding: 1rem;
  min-height: 20rem;
  border: 1px solid #ccc;
  text-align: center;
}

.app__preview .image__block img {
  width: 180px;
  margin-bottom: 10px;
}

.app__preview .image__block a {
  display: block;
  color: #24abe2;
  margin-bottom: 1rem;
  text-decoration: none;
}

.outgoing__mapping .mapping_chkbxdiv {
  padding-left: 10px;
}

.outgoing__mapping .mapping_chkbxdiv input {
  vertical-align: middle;
}

.outgoing__mapping .mapping_chkbxdiv label {
  margin-left: 8px;
}

.steps__section {
  background: #f6f7f9;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.steps__section .workarea__heading {
  text-align: center;
  font-size: 18px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.steps__section .steps__block {
  text-align: center;
}
.steps__section .steps__block h4 {
  font-size: 16px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}
.steps__section .steps__block img {
  width: 130px;
  height: 130px;
  object-fit: contain;
}

/*Vendors Custom CSS Starts*/
.vendors-section .star-ratings {
  color: #24abe2;
}
/*Vendors Custom CSS Ends*/
.vert-table .compare-row-sp {
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem;
}
.vert-table .compare-row-sp:hover {
  background: #fafafa;
}
.vert-table .action__btns .image__block {
  border: none;
  min-height: auto;
}
.vert-table .action__btns .image__block img {
  margin-bottom: 0;
}
.compare-btn-section {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.compare-btn-section .primary-btn {
  margin-right: 1rem;
}

/*Watch Customizer Starts*/
.request-info {
  margin-top: 2rem;
}
.request-info-links {
  margin-top: 1rem;
}
.request-info-links a {
  display: block;
  color: #24abe2;
  margin-bottom: 1rem;
  text-decoration: none;
}
.request-info-links a:hover {
  color: #24abe2;
}
.selection__block .selection__box.selected {
  border: 1px solid #24abe2;
}
.selection__block .selection__box {
  border: 1px solid #ccc;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}
.selection__block .selection__box:hover {
  border: 1px solid #24abe2;
}
.watch-desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selection-title h5 {
  font-size: 15px;
  margin-bottom: 1rem;
}
.category h4 {
  font-size: 15px;
  color: #24abe2;
}
.img-section-sp {
  height: 70%;
}
.watch-desc-sm h4 {
  font-size: 12px;
  color: #24abe2;
  margin-bottom: 0;
}

.specifications-heading h5 {
font-size: 15px;
}
/* Watch Customizer Ends */

.pendjewelreq__section .pendjewelreq__tbl .icn__div .chck_icn {
  color: #23d723;
  font-size: 18px;
  cursor: pointer;
}

.pendjewelreq__section .pendjewelreq__tbl .icn__div .clse_icn {
  color: red;
  font-size: 18px;
  margin-left: 15px;
  cursor: pointer;
}

.table-links a {
  margin-right: 1rem;
}
/*marketing starts*/
.form__fields .input__block .image_block .file-upload-sp {
  border: none;
  text-align: left;
  padding: 0 10px;
}

.apikeytxt {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  width: 100%;
}

/*marketing ends*/

/*Header Dropdown Avatar Starts*/
.dropdown-menu-sp {
  border-radius: 5px;
  position: relative;
  width: 200px;
  top: 17px;
}
.dropdown-menu-sp::before {
  content: "";
  position: absolute;
  top: -12px;
  right: 13px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #ffffff;
}
.dropdown-menu-sp li {
  color: #3B4452;
  padding: 8px 12px;
}
.dropdown-menu-sp li:hover {
  background-color: #EFEFEF;
}
.dropdown-menu-sp a {
  text-decoration: none;
}
.dropdown-menu-sp li label img {
  width: 16px;
  margin-right: 10px;
}
.dropdown-menu-sp li label {
  display: flex;
  align-items: center;
}
.dropdown-menu-sp li label.logout__txt {
  color: #DD0000;
}
/*Header Dropdown Avatar Ends*/

.essential__section .btns__div {
  display: flex;
  justify-content: flex-end;
}

.essential__section .datamapping__tbl .tags__ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.essential__section .datamapping__tbl .tags__lbl {
  display: inline-block;
  padding: 6px;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  background: #fff;
}

.essential__section .datamapping__tbl .tags__lbl:not(:first-child) {
  margin-left: 10px;
}

.essential__section .datamapping__tbl .tags__lbl .tag__name {
  font-size: 13px;
}

.essential__section .datamapping__tbl .tags__lbl .close__icn {
  margin-left: 15px;
  cursor: pointer;
  width: 9px;
}

.essential__section .datamapping__tbl .hamb__icn {
  width: 18px;
  cursor: pointer;
}

.essential__section .prodesc__div .tab__div .nav-item {
  margin: 0 !important;
  flex: 1;
}
.essential__section .prodesc__div .tab__div .nav-item button {
  width: 100%;
}

.essential__section .prodesc__div .tab__contentdiv {
  max-height: 300px;
  min-height: 300px;
  overflow: auto;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.essential__section .prodesc__div .tab__contentdiv .tags__ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.essential__section .prodesc__div .tab__contentdiv .tags__lbl {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 10px;
  flex: 46% 1;
  margin: 0 2%;
  margin-top: 15px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.essential__section .prodesc__div .tab__contentdiv .tags__lbl.active {
  border: 1px solid #bebfb6;
}

.essential__section .prodesc__div .tab__contentdiv .tags__lbl .tag__name {
  font-size: 13px;
}

.essential__section .prodesc__div .tab__contentdiv .tags__lbl .close__icn {
  margin-left: 15px;
  cursor: pointer;
}

.essential__section .desc__div .count__div {
  display: flex;
  position: relative;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  padding: 10px 35px;
}

.essential__section .desc__div .head__txt {
  display: block;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.essential__section .desc__div .desc__txt {
  display: block;
  color: #000;
  font-size: 14px;
}

.essential__section .desc__div .count__div .count__indiv {
  display: block;
  text-align: center;
}

.essential__section .desc__div .count__div .count__indiv .no__txt {
  display: block;
  color: #24abe2;
  font-size: 16px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.essential__section .desc__div .count__div .count__indiv .counthead__txt {
  display: block;
  color: #000;
  font-size: 14px;
}

.essential__section .desc__div .count__div .reset__div {
  position: absolute;
  top: 2px;
  right: 10px;
  cursor: pointer;
}

.essential__section .desc__div .content__div {
  background-color: #eefaff;
  border: 1px solid #24abe2;
  border-radius: 10px;
  padding: 10px 15px;
  text-align: center;
}

.essential__section .desc__div .content__div .conthead__txt {
  font-size: 15px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
  color: #1883b8;
}

.essential__section .desc__div .content__div .desc__txt {
  font-size: 14px;
  color: #000;
}

.datamapping__modal .head__div {
  background-color: #3b4452;
  border: 1px solid #3b4452;
  padding: 10px;
}

.datamapping__modal .head__div span {
  color: #fff;
  font-size: 15px;
}

.datamapping__modal .inptsrch__div {
  text-align: right;
}

.datamapping__modal .Addnewmap__tbl .action__btns {
  justify-content: flex-end;
}

.datamapping__modal .Addnewmap__tbl .tags__lbl {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 10px;
  border: 1px solid #bebfb6;
  line-height: 24px;
  white-space: nowrap;
}

.datamapping__modal .Addnewmap__tbl .tags__lbl .tag__name {
  font-size: 13px;
}

.datamapping__modal .Addnewmap__tbl .tags__lbl .close__icn {
  margin-left: 15px;
  cursor: pointer;
}

.datamapping__modal .imptmap__headdiv {
  border-bottom: 1px solid #bebfb6;
  padding-bottom: 6px;
}

.datamapping__modal .imptmap__headdiv span {
  color: #000;
  font-size: 15px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.datamapping__modal .excel__maindiv {
  display: flex;
  align-items: center;
}

.datamapping__modal .excel__maindiv .excel__icndiv img {
  width: 35px;
}

.datamapping__modal .excel__maindiv .excel__headtxtdiv {
  margin-left: 10px;
}

.datamapping__modal .excel__maindiv .excel__headtxtdiv .head__txt {
  display: block;
  font-size: 15px;
  /* font-weight: 600; */
  font-family:'NoirPro-Medium';
}

.datamapping__modal .excel__maindiv .excel__headtxtdiv .extn__txt {
  display: block;
  font-size: 15px;
}

.datamapping__modal .excelfileupl__div input[type="file"] {
  display: none;
}

.datamapping__modal .excelfileupl__div .choose_file_upload {
  background: #1883b8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 8px 16px;
  display: inline-block;
  height: 40px;
  cursor: pointer;
}

.datamapping__modal .excelfileupl__div .choose_file_upload:hover {
  background: #24abe2;
}

.datamapping__modal .excelfileupl__div .filename__txt {
  color: #000;
  font-size: 14px;
  margin-top: 4px;
  cursor: pointer;
}

.datamapping__modal .excelfileupl__div .filename__txt img {
  width: 8px;
  margin-left: 4px;
  vertical-align: baseline;
}

.permission__section .right__section {
background: #f6f7f9;
padding: 16px;
border-radius: 10px;
height: 100%;
width: 100%;
}

.permission__section .right__section .profile__block .retailer__name {
color: #24abe2;
text-decoration: none;
font-size: 15px;
display: block;
margin-bottom: 16px;
}

.permission__section .right__section .profile__block .logoImg__block {
width: 100%;
height: 15rem;
display: flex;
align-items: center;
justify-content: center;
background: #fff;
border: 1px solid #ccc;
overflow: hidden;
border-radius: 10px;
margin-bottom: 10px;
padding: 10px;
}

.permission__section .right__section .profile__block .activeFrom {
font-size: 12px;
}

.permission__section .right__section .links__block ul {
list-style: none;
padding-left:0;
}

.permission__section .right__section .links__block ul li {
margin-bottom:10px ;
}

.permission__section .right__section .links__block ul li a {
color: #24abe2;
text-decoration: none;
}

.permission__section .right__section .retailer__dtls a {
color: #24abe2;
text-decoration: none;  
}

.apiendpoint .button__block a {
  color: #24abe2;
  text-decoration: none;
  display: block;
  margin-top: 1rem;
}
.row-active {
  background: #efefef;
}

.err-text-sp {
  color: red;
}

.help-doc-sp {
  display: flex;
}
.help-doc-sp img {
  margin-right: 1rem;
}
.addCollection-img-sp {
  width: 100%;
  display: block;
  margin: 0 auto;
}
.help-doc-sp {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.help-doc-sp p {
  margin-bottom: 0;
}

.help-doc-sp a {
  color: #24abe2;
  text-decoration: none;
}

.help-doc-sp img {
  margin-right: 5px;
}

.ringbuild__divbg {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px 8px;
}

.ringbuild__divbg:nth-child(even) {
  background-color: #f7f8f9;
}

.ringbuild__divbg .chkbox__div {
  display: flex;
  align-items: center;
  height: 100%;
}

.ringbuild__divbg .input__block {
  margin: 0;
}

.ringbuild__divbg label {
  margin-left: 4px;
  cursor: pointer;
}

.retailerprof__section .tab__contentdiv .note__headdiv {
  padding: 0px 10px;
}

.retailerprof__section .tab__contentdiv .note__headdiv .note__txt {
  font-size: 18px;
  margin-right: 15px;
}

.retailerprof__section .tab__contentdiv .note__headdiv .line__txt {
  font-size: 18px;
}

.retailerprof__section .tab__contentdiv .note__headdiv .createnote__txt {
  font-size: 20px;
  color: #1883b8;
  cursor: pointer;
  margin-left: 15px;
}

.retailerprof__section .tab__contentdiv .notedesc__maindiv {
  background: #ffffff;
  margin-top: 2%;
}

.retailerprof__section .tab__contentdiv .notedesc__maindiv .date__headdiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #f6f7f9;
  padding: 10px 14px;
}

.retailerprof__section .tab__contentdiv .notedesc__maindiv .date__headdiv .edit__txt {
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
}

.retailerprof__section .tab__contentdiv .notedesc__maindiv .date__headdiv .line__txt {
  font-size: 14px;
}

.retailerprof__section .tab__contentdiv .notedesc__maindiv .date__headdiv .del__txt {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.retailerprof__section .tab__contentdiv .notedesc__maindiv .msg__headdiv {
  background: #fff;
  padding: 10px 14px;
}

.retailerprof__section .tab__contentdiv .contact__tbl .user__icon {
  font-size: 20px;
  color: grey;
}

.choose_videobtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebfb6;
  border-radius: 10px;
  opacity: 1;
  padding: 6px 5px;
  width: 100%;
  height: 35px;
  color: #3b4452;
  white-space: nowrap;
  overflow: hidden;
}

.choose_videobtn label {
  background: #efefef;
  display: inline !important;
  padding: 3px 6px;
  border: 1px solid #a4a4a4;
  color: #3b4452;
  cursor: pointer;
  margin-right: 4px;
}

.alert__popup {
margin-top:2rem;
text-align: center;
}

.alert__popup p {
font-size: 16px;
}

.alert__popup p b {
font-size: 16px;
}

.ant-menu-dark .ant-menu-item-selected > span > a {
color: #24abe2;
}

/*Upload Diamond file custom CSS starts*/
.uploadDiamondCheckbox-sp{
  display: flex;
}
.form__fields .input__block .uploadDiamondCheckbox-sp input{
  width: 20px;
  margin-right: 5px;
  height: 18px;
}
/*Upload Diamond file custom CSS ends*/

@media (min-width: 1367px) and (max-width: 4000px) {

  .login__section {
    height: 100vh;
  }

  .footer__section.login__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .login__section .slider__section {
    padding: 20px 0;
    border-top: 1px solid #bebfb6;
    margin-top: 10px;
    position: absolute;
    bottom: 4rem;
  }
}

@media (max-width: 1360px) {
  /* .login__section {
  height: auto;
  }
  
  .footer__section.login__footer {
  position: relative;
  width: 100%;
  }
  
  .login__section .slider__section {
  position: relative;
  } */

  .main__section .header__section .topnav__toggle {
    display: block;
  }

  .main__section .header__section .topnav__toggle h3 {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    cursor: pointer;
  }

  .main__section .header__section .topnav__toggle h3 i {
    font-size: 10px;
    margin-left: 5px;
  }

  .main__section .header__section .top__nav {
    position: absolute;
    top: 75px;
    z-index: 2;
  }

  .main__section .header__section .search__block {
    margin-right: 1rem;
  }

  .main__section .header__section .top__nav.show {
    /* opacity:1;
    transition: all .5s;
    visibility: visible; */
    display: block;
  }

  .main__section .header__section .top__nav {
    /* opacity:0;
    transition: all .5s;
    visibility: hidden; */
    display: none;
  }

  .main__section .header__section .top__nav ul {
    display: block;
    background: #1f263e;
    width: 15rem;
    padding:5px 0;
    /* border-radius: 10px; */
  }

  .main__section .header__section .top__nav ul li a:hover {
    color: #24abe2;
  }

  .main__section .header__section .top__nav ul li a:after {
    display: none;
  }

  .main__section .header__section .top__nav ul li:hover:after {
    display: none;
  }

  .main__section .header__section .top__nav ul li {
  padding: 5px 15px;
  }

  .main__section .header__section .top__nav ul li:hover {
  background: #303750;
  }

  .main__section .header__section .top__nav ul li.active {
  background: #303750;
  }

  .main__section .header__section .top__nav li.active:after {
  display: none;
  }

  .main__section .header__section .top__nav ul li a label {
  color:#bebfb6;
  }

  .main__section .header__section .top__nav ul li:hover a label {
  color:#24abe2;
  }

  .main__section .header__section .top__nav ul li.active a label {
  color:#24abe2;
  }

  .main__section .sidenav__section .logo__section .logobig {
    width: 100px;
  }

  .main__section .sidenav__section.partialwidth {
    width: auto;
  }

  /* .footer__section.login__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  } */
}

@media (max-width: 991px) {

  .main__section .header__section .top__nav ul {
    background:#001529;
  }
  .ant-table table {
    width: 1000px;
  }

  .footer__section.login__footer {
    position: relative;
    display: block;
  }

  .login__section .login__slider {
    position: relative;
  }

  .footer__section.login__footer .left__block {
    display: block;
  }

  .footer__section.login__footer .left__block .footer__logo {
    text-align: center;
  }

  .footer__section.login__footer .left__block .footer__logo img {
    margin: 1rem auto;
  }

  .footer__section.login__footer .left__block .footer__menu ul {
    padding-left: 0;
    justify-content: center;
  }

  .footer__section.login__footer .right__block ul {
    justify-content: center;
  }

  .login__section {
    height: auto;
  }

  .login__section .left__section {
    margin-bottom: 2rem;
    margin-top: 2rem;
    min-height: auto;
  }

  .main__section .footer__section {
    display: block;
  }

  .main__section .footer__section .left__block {
    display: block;
  }

  .main__section .footer__section .left__block .footer__logo {
    text-align: center;
  }

  .main__section .footer__section .left__block .footer__logo img {
    margin: 1rem auto;
  }

  .main__section .footer__section .left__block .footer__menu ul {
    padding-left: 0;
    justify-content: center;
  }

  .main__section .footer__section .right__block ul {
    justify-content: center;
  }

  .main__section .sidenav__section {
    width: 25%;
  }

  .main__section .sidenav__section.partialwidth {
    width: 10%;
  }

  .main__section .right__section {
    width: 75%;
  }

  .main__section .right__section.partialwidth {
    width: 90%;
  }

  .main__section
    .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title {
    padding: 0;
    text-align: center;
  }

  .main__section
    .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .ant-menu-item-icon
    + span {
    display: none;
  }

  .allprod_section .filter_section .filter_inpsection .filtbtn_div {
    justify-content: center;
  }

  .allprod_section .filter_section .filt_optndiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .allprod_section .filter_section .filt_optndiv .gview__div {
    flex: 100%;
    text-align: center;
  }

  .allprod_section .filter_section .filt_optndiv .drpdwn__div {
    flex: 60%;
    text-align: center;
    margin-top: 10px;
  }

  .allprod_section .filter_section .filt_optndiv .filtbtn__div {
    flex: 40%;
    text-align: center;
    margin-top: 10px;
  }

  .allprod_section .filter_section .filt_optndiv .gview__div .grid_icon {
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: #bebfb6;
    background: #f6f7f9;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #bebfb6;
    cursor: pointer;
    vertical-align: middle;
  }

  .allprod_section .filter_section .filt_optndiv .gview__div .list_icon {
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: #bebfb6;
    background: #f6f7f9;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #bebfb6;
    margin-left: 8px;
    cursor: pointer;
    vertical-align: middle;
  }

  .allprod_section .filter_section .filt_optndiv .gview__div .left_icon {
    width: 30px;
    height: 30px;
    font-size: 20px;
    text-align: center;
    color: #bebfb6;
    background: #f6f7f9;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #bebfb6;
    margin-left: 4px;
    margin-right: 6px;
    cursor: pointer;
    vertical-align: middle;
  }

  .allprod_section .filter_section .filt_optndiv .gview__div .right_icon {
    width: 30px;
    height: 30px;
    font-size: 20px;
    text-align: center;
    color: #bebfb6;
    background: #f6f7f9;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid #bebfb6;
    margin-left: 6px;
    cursor: pointer;
    vertical-align: middle;
  }

  .allprod_section .filter_section .filt_optndiv .gview__div .pageno__txt {
    display: inline-block;
    width: 70px;
    height: 32px;
    background: #f6f7f9 0% 0% no-repeat padding-box;
    border: 1px solid #bebfb6;
    border-radius: 10px;
    text-align: center;
    padding: 4px;
  }

  .allprod_section
    .filter_section
    .filt_optndiv
    .drpdwn__div
    .select_inpt
    .ant-select-selector {
    width: 60px;
    font-size: 15px;
    color: #bebfb6;
    background: #f6f7f9;
    border-radius: 6px;
    border: 1px solid #bebfb6;
  }

  .allprod_section .btn_div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .allprod_section .btn_div .primary-btn {
    flex: 100%;
    margin-top: 12px;
    margin-right: 0;
  }

  .designer__list .designer__block {
    width: 50%;
  }

  .prodetail__section .prod__maindiv .prodesc__div .tab__div {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }
  .prodetail__section
    .prod__maindiv
    .prodesc__div
    .tab__contentdiv
    .desc__maindiv
    .desc__div
    .head__txt {
    flex: 40%;
  }
  .prodetail__section
    .prod__maindiv
    .prodesc__div
    .tab__contentdiv
    .desc__maindiv
    .desc__div
    .desc__txt {
    flex: 60%;
  }
}

@media (max-width: 767px) {
  .ant-table table {
    width: 800px;
  }

  .mobilebtn {
    width: 100%;
    margin-top: 1rem;
  }

  .login__section .login__header {
    display: block;
    text-align: center;
  }

  .login__section .login__header .logo__section img {
    margin: 1rem auto;
  }

  .login__section .form__section .login__block {
    width: 21rem;
  }

  .footer__section.login__footer .left__block .footer__menu ul {
    display: block;
    text-align: center;
  }

  .footer__section .right__block ul li a img {
    width: 45px;
    height: 45px;
  }

  .desktop__icon {
    display: none;
  }

  .mobile__icon {
    display: block;
  }

  .main__section .header__section .search__block {
    display: none;
  }

  .main__section.hideMobileMenu .sidenav__section {
    position: absolute;
    transform: translate(-14rem);
    transition: all 0.5s;
  }

  .main__section.showMobileMenu .sidenav__section {
    position: absolute;
    transform: translate(0);
    transition: all 0.5s;
    width: 15rem;
    z-index: 3;
  }

  .mobileMenuclose {
    position: absolute;
    background: #fff;
    z-index: 2;
    top: 0;
    right: -30px;
    color: #1f263e;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .main__section .showMobileMenu {
    transform: translate(0);
    transition: all 0.5s;
  }

  .main__section .sidenav__section {
    transform: translate(0);
  }

  .main__section .right__section,
  .main__section .right__section.partialwidth {
    width: 100%;
  }

  .allprod_section .filter_section .filt_optndiv .drpdwn__div {
    flex: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .allprod_section .filter_section .filt_optndiv .filtbtn__div {
    flex: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .sliderrange__maindiv {
    margin-bottom: 80px;
  }

  .main__section .footer__section .left__block .footer__menu ul {
    display: block;
    text-align: center;
  }

  .prodetail__section
    .prod__maindiv
    .prodesc__div
    .tab__contentdiv
    .desc__maindiv
    .desc__div
    .head__txt {
    flex: 100%;
  }

  .prodetail__section
    .prod__maindiv
    .prodesc__div
    .tab__contentdiv
    .desc__maindiv
    .desc__div
    .desc__txt {
    flex: 100%;
  }

  .data__maping .input__field {
    width: 100%;
  }

  .designer__list .designer__block {
    width: 100%;
  }

  .navigation__section .form__fields .form__section .desc__div {
    flex-direction: column;
  }

  .navigation__section .form__fields .form__section .desc__div .inpt__div {
    margin-top: 10px;
  }
}
.close_image {
  color: red !important;
  text-align: right;
  font-size: 14px;
  cursor: pointer;
}
